<template>
  <div class="space-y-2">
    <inputs-label :for="id" :title="`${title} ${required ? '*' : ''}`"/>

    <input :id="id"
           :autofocus="autofocus"
           :name="name ?? id"
           :placeholder="placeholder"
           :required="required"
           :value="value"
           class="appearance-none w-full input"
           type="email"
           @keyup="emit('set', $event)">
  </div>
</template>

<script setup>
defineProps({
  id: {
    type: String,
    required: true,
  },

  title: {
    type: String,
    required: true,
  },

  autofocus: {
    type: Boolean,
    required: false,
  },

  name: {
    type: String,
    required: false,
  },

  placeholder: {
    type: String,
    required: false,
  },

  value: {
    type: String,
    required: false,
  },

  required: {
    type: Boolean,
    required: false,
  },
})

const emit = defineEmits(['set'])
</script>
